import React, { useEffect, useState } from 'react'
import { Buttons } from '../../CustomUIElements/Buttons/Buttons'
import useAuthStore from '../../store/authStore'
import Inputs from '../../CustomUIElements/Inputs/Inputs'
import Scale from '../../CustomUIElements/Loaders/ScaleLoader'
import axios from 'axios'
import { addGrade, deleteGrade, getLIGrades, updateGrade } from '../../utils/addSetting'
import { toast } from 'react-toastify'

function Settings() {
    const [tab, setTab] = useState("profile")
    const { userProfile, removeUser } = useAuthStore()
    const [loading, setLoading] = useState(false)
    const [grading, setGrading] = useState([
        {
            minScore: 0,
            maxScore:0,
            value: ""
        }
    ])
    const [isSelected, setIsSelected] = useState("")
    const fetchGrades = async () => {
        setLoading(true)
        await getLIGrades({ id: userProfile?.user?.learningInstitutionId }).then((res) => {
            console.log(res?.data?.data)
            if(res?.data?.data?.length < 1){
                setGrading([
                    {
                        minScore: "",
                        maxScore:"",
                        value: ""
                    }
                ])
            } else {
                setGrading(res?.data?.data)
            }
            setLoading(false)
        }).catch((err) => {
            if (err?.response?.status == 401) {
                removeUser()
                window.location.href = "/login"
            }
            console.log(err)
            setLoading(false)
        })
    }
    useEffect(() => {
        fetchGrades()
    }, [])
    return (
        <div className="relative top-[8%] px-16 py-8 bg-[#f1f1f1] min-h-screen">
            <h1 className="text-[20px] font-semibold mb-[3rem]">Settings</h1>
            <div className='my-[24px] grid grid-cols-12 h-[70vh]'>
                <div className='col-span-3 border border-gray-400 py-[24px]'>
                    <p className={`text-[15px] p-4 mb-4 cursor-pointer hover:bg-[#008FD2] hover:text-white px-[24px] ${tab === "profile" && "bg-[#008FD2] text-white"}`} onClick={() => setTab("profile")}>Profile</p>
                    <p className={`text-[15px] p-4 mb-4 cursor-pointer hover:bg-[#008FD2] hover:text-white px-[24px] ${tab === "customization" && "bg-[#008FD2] text-white"}`} onClick={() => setTab("customization")}>Customization</p>
                    <p className={`text-[15px] p-4 mb-4 cursor-pointer hover:bg-[#008FD2] hover:text-white px-[24px] ${tab === "subscription" && "bg-[#008FD2] text-white"}`} onClick={() => setTab("subscription")}>Subscription</p>
                    <p className={`text-[15px] p-4 mb-4 cursor-pointer hover:bg-[#008FD2] hover:text-white px-[24px] ${tab === "grading" && "bg-[#008FD2] text-white"}`} onClick={() => setTab("grading")}>Grading System</p>
                </div>
                <div className='col-span-9  border-gray-400 bg-[#008FD2] p-[24px]'>
                    {tab === "profile" && <Profile userProfile={userProfile} />}
                    {tab === "customization" && <Customization />}
                    {tab === "subscription" && <Subscription />}
                    {tab === "grading" && <Grading grading={grading} setGrading={setGrading} isSelected={isSelected} setIsSelected={setIsSelected} fetchGrades={fetchGrades} removeUser={removeUser} userProfile={userProfile} loading={loading} />}
                </div>
            </div>
        </div>
    )
}

export default Settings

const Profile = ({ userProfile }) => {
    const [user, setUser] = useState(userProfile?.user || {})
    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setUser((prev) => ({ ...prev, [name]: value }))
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(user)
    }
    return (
        <div className='text-white'>
            <div className='flex items-center justify-between mb-[16px]'>
                <p className='text-[19px] font-semibold'>Profile Information</p>
                <div className='w-fit'>
                    <Buttons text={"Save Changes"} variant={"cancel"} handleClick={handleSubmit} />
                </div>
            </div>
            <div className='space-y-8'>
                <input type="text" className='w-full p-3 border bg-transparent border-white text-white placeholder:text-white rounded-md text-[15px] outline-none' placeholder='First Name' value={user?.firstName} name='firstName' onChange={handleChange} />
                <input type="text" className='w-full p-3 border bg-transparent border-white text-white placeholder:text-white rounded-md text-[15px] outline-none' placeholder='Last Name' value={user?.lastName} name='lastName' onChange={handleChange} />
                <input type="email" className='w-full p-3 border bg-transparent border-white text-white placeholder:text-white rounded-md text-[15px] outline-none' placeholder='Email Address' value={user?.email} name='email' onChange={handleChange} />
                <input type="text" className='w-full p-3 border bg-transparent border-white text-white placeholder:text-white rounded-md text-[15px] outline-none' placeholder='Phone Number' value={user?.phoneNumber} name='phoneNumber' onChange={handleChange} />
                <input type="text" className='w-full p-3 border bg-transparent border-white text-white placeholder:text-white rounded-md text-[15px] outline-none' placeholder='Institution Name' value={user?.organisationName} name='organisationName' onChange={handleChange} />
                {userProfile?.user?.userRole === "Lecturer" &&
                    <input type="text" className='w-full p-3 border bg-transparent border-white text-white placeholder:text-white rounded-md text-[15px] outline-none' placeholder='Course Title' value={user?.courseName} name='courseName' onChange={handleChange} />
                }
            </div>
        </div>
    )
}
const Customization = () => {
    return (
        <div className='text-white'>
            <div className='flex items-center justify-between mb-[16px]'>
                <p className='text-[19px] font-semibold'>Customization</p>
                <div className='w-fit'>
                    <Buttons text={"Save Changes"} variant={"cancel"} />
                </div>
            </div>
            <div className='space-y-8'>
                <div>
                    <label htmlFor="" className='w-[200px] h-[200px] rounded-md flex justify-center items-center p-4 border border-white text-white placeholder:text-white'>
                        <input type="file" name="" id="" className='hidden' />
                        <p className='text-center text-[15px]'>Upload Institution Logo</p>
                    </label>
                </div>
                <input type="text" className='w-full p-3 border bg-transparent border-white text-white placeholder:text-white rounded-md text-[15px] outline-none' placeholder='Primary Color' />
                <input type="text" className='w-full p-3 border bg-transparent border-white text-white placeholder:text-white rounded-md text-[15px] outline-none' placeholder='Secondary Color' />
            </div>
        </div>
    )
}

const Subscription = () => {
    return (
        <div className='text-white text-[16px]'>
            <p>You're currently subscribed to Hyderate&apos;s <span className='font-semibold underline'>Education</span> plan.</p>
        </div>
    )
}

const Grading = ({ grading, setGrading, isSelected, setIsSelected, fetchGrades, removeUser, userProfile, loading }) => {
    return (
        <div>
            <p className='text-[16px] text-white mb-8'>Use the interface below to define your Learning Institution&apos;s grading system</p>
            <div className='space-y-4'>
                {loading ? <center><Scale color="white" /></center> :
                    <>
                        {grading?.map((item, index) => (
                            <Grade key={index} item={item} grading={grading} setGrading={setGrading} isSelected={isSelected} onClick={() => setIsSelected(index)} index={index} fetchGrades={fetchGrades} removeUser={removeUser} userProfile={userProfile} />
                        ))}
                        {grading && [...Array(Math.max(0, 7 - parseInt(grading?.length || 0)))]?.map((item, index) => (
                            <Grade key={index} item={item} grading={grading} setGrading={setGrading} isSelected={isSelected} onClick={() => setIsSelected(index)} index={index} fetchGrades={fetchGrades} removeUser={removeUser} userProfile={userProfile} />
                        ))}
                    </>
                }
            </div>
        </div>
    )
}

const Grade = ({ item, setGrading, isSelected, onClick, index, grading, fetchGrades, removeUser, userProfile }) => {
    const [loading, setLoading] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const handleInputChange = (e, field) => {
        const value = field === 'value' ? e.target.value : e.target.valueAsNumber;

        // Create a new array with the updated value
        const updatedGrading = grading.map((gradeItem, i) =>
            i === index ? { ...gradeItem, [field]: value } : gradeItem
        );

        setGrading(updatedGrading);
    };

    const handleSave = async (e, index) => {
        e.preventDefault()
        setLoading(true)
        const gradeToSave = grading[index];
        console.log('Saved Grade:', gradeToSave);
        await addGrade({ minScore: parseInt(gradeToSave.minScore), maxScore: parseInt(gradeToSave.maxScore), value: gradeToSave.value }).then((res) => {
            console.log(res?.data)
            setLoading(false)
            toast.success("Grade added successfully")
            fetchGrades()
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    };

    const handleUpdate = async (e, index) => {
        const gradeToSave = grading[index];
        console.log('Saved Grade:', gradeToSave);
        e.preventDefault()
        setIsUpdating(true)
        await updateGrade({
            id: gradeToSave?.id,
            minScore: gradeToSave?.minScore,
            maxScore: gradeToSave?.maxScore,
            value: gradeToSave?.value,
            isActive: true
        }).then((res) => {
            setIsUpdating(false)
            fetchGrades()
            toast.success("Grade updated successfully")
        }).catch((err) => {
            if (err?.response?.status == 401) {
                removeUser()
                window.location.href = "/login"
            }
            setIsUpdating(false)
        })
    }

    const handleDelete = async (e, index) => {
        const gradeToDel = grading[index];
        console.log('Saved Grade:', gradeToDel);
        setIsDeleting(true)
        await deleteGrade({ value1: gradeToDel?.id, value2: userProfile?.user?.learningInstitutionId }).then(() => {
            toast.success("Grade deleted successfully")
            fetchGrades()
            setIsDeleting(false)
        }).catch((err) => {
            if (err?.response?.status == 401) {
                removeUser()
                window.location.href = "/login"
            }
            setIsDeleting(false)
        })
    }

    return (
        <form className='flex gap-5 items-end' onClick={onClick} onSubmit={(e) => handleSave(e, index)}>
            <div>
                <p className='text-white text-[15px] mb-2'>Min</p>
                <div className='w-[100px]'>
                    <input
                        type="number"
                        className='w-full p-4 rounded-md text-[14px] outline-none'
                        placeholder='Min score'
                        min={0}
                        max={100}
                        value={item?.minScore}
                        onChange={(e) => handleInputChange(e, 'minScore')}
                        required
                    />
                </div>
            </div>
            <div>
                <p className='text-white text-[15px] mb-2'>Max</p>
                <div className='w-[100px]'>
                    <input
                        type="number"
                        className='w-full p-4 rounded-md text-[14px] outline-none'
                        placeholder='Max score'
                        min={0}
                        max={100}
                        value={item?.maxScore}
                        onChange={(e) => handleInputChange(e, 'maxScore')}
                        required
                    />
                </div>
            </div>
            <div>
                <p className='text-white text-[15px] mb-2'>Grade</p>
                <div className='w-[100px]'>
                    <input
                        type="text"
                        className='w-full p-4 rounded-md text-[14px] outline-none'
                        placeholder='Grade'
                        value={item?.value?.toUpperCase()}
                        onChange={(e) => handleInputChange(e, 'value')}
                        required
                    />
                </div>
            </div>
            {item?.id && item?.minScore >= 0 && item?.maxScore && item?.value ?
                <div className='flex gap-5'>
                    <div className='w-[100px]'>
                        <div type='button' className='p-4 rounded-md bg-white text-[#008FD2] text-[14px] w-full flex justify-center items-center cursor-pointer' disabled={index !== isSelected} onClick={(e) => handleUpdate(e, index)}>{isUpdating ? <Scale height={10} /> : "Update"}</div>
                    </div>
                    <div className='w-[100px]'>
                        <div type='button' className='p-4 rounded-md bg-white text-red-400 text-[14px] w-full flex justify-center items-center cursor-pointer' disabled={index !== isSelected} onClick={(e) => handleDelete(e, index)}>{isDeleting ? <Scale height={10} /> : "Delete"}</div>
                    </div>
                </div>
                :

                <div className='w-[100px]'>
                    <button className='p-4 rounded-md bg-white text-[#008FD2] text-[14px] w-full' disabled={index !== isSelected}>{loading ? <Scale height={10} /> : "Save"}</button>
                </div>
            }
        </form>
    )
}
