import { FaRegUserCircle } from "react-icons/fa";
import { useTheme } from "../../context/ThemeContext";
import useAuthStore from "../../store/authStore";
import { FiBell } from "react-icons/fi";
import Badge from "../../CustomUIElements/Badge/Badge";
import { Link } from "react-router-dom";
function Navbar() {
  const { theme } = useTheme();

  const { userProfile, userNotifications, userNotificationsCount, notificationsCount } = useAuthStore();
  // console.log(userNotifications);


  // Define theme-specific styles
  const themeStyles = {
    light: {
      backgroundColor: "#fff",
      color: "#333",
    },
    dark: {
      backgroundColor: "#333",
      color: "#f0f0f0",
    },
    blue: {
      backgroundColor: "#007bff",
      color: "#f0f0f0",
    },
  };
  return (
    <div
      className="fixed top-0 h-[9%] w-10/12 z-20 flex items-center px-16 shadow-md"
      style={{ ...themeStyles[theme] }}
    >
      <div className="relative flex justify-end items-center w-full">
        {/*
      <div>Icon Here</div>
    */}

        <div className="flex items-center gap-8">
          <Link to="notifications" className="relative">
            <FiBell size={20} color="gray" />
            {
              userNotificationsCount > 0 &&(
                <Badge count={userNotificationsCount} /> 
              )  
            }

            {console.log(notificationsCount)}
          </Link>
          <div className="flex gap-2 items-center">
            <FaRegUserCircle size={28} color="gray" />
            <span className="">
              <p className="text-[1.4rem] font-semibold">{userProfile?.user?.firstName + " " + userProfile?.user?.lastName} </p>
              <p className="text-[1.2rem]">{userProfile?.user?.userRole}</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
