import { useState } from "react";
import { Buttons } from "../../CustomUIElements/Buttons/Buttons";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import useAuthStore from "../../store/authStore";

function NavbarComponent() {
  const [activeLink, setActiveLiink] = useState("home");
  const [showMenu, setShowMenu] = useState(false);
  const { userProfile, removeUser } = useAuthStore()

  //   save the active link in the localStorage
  const toggleActiveLink = (link) => {
    setActiveLiink(link);
    window.localStorage.setItem("activeLink", link);
  };

  //   function to get the active link from the localStorage
  const getActiveLink = window.localStorage.getItem("activeLink");

  return (
    <header className="fixed left-0 right-0 md:sticky top-0 bg-white z-50">
      <div className="app-container">
        <div className="mx-auto max-h-auto  md:max-h-[8rem] flex justify-between items-center w-full uppercase py-[1.4rem] md:py-[2.4rem]">
          <div className="md:flex items-center lg:block">
            <div className="hidden md:block lg:hidden">
              {/* show menu on menu screen devices e.g iPads */}
              {showMenu ? (
                <AiOutlineClose
                  size={25}
                  onClick={() => setShowMenu(false)}
                  className="cursor-pointer"
                />
              ) : (
                <AiOutlineMenu
                  size={25}
                  onClick={() => setShowMenu(true)}
                  className="cursor-pointer"
                />
              )}

              <div
                className={`absolute w-full top-[10rem] bg-white z-50 ${showMenu ? "left-0" : "-left-full"
                  } duration-300 min-h-screen text-left pl-[6rem] pt-[6rem] space-y-[3rem]`}
              >
                <ul className=" flex flex-col gap-[3rem] font-medium text-[3rem]">
                <li
                className={`cursor-pointer`}
                onClick={() => toggleActiveLink("about")}
              >
                <a href="/#about">about us</a>
              </li>
              <li
                className={`cursor-pointer`}
                onClick={() => toggleActiveLink("vision")}
              >
                <a href="/#vision">vision</a>
              </li>
              <li
                className={`cursor-pointer `}
                onClick={() => toggleActiveLink("mission")}
              >
                <a href="/#mission">mission</a>
              </li>
              <li
                className={`cursor-pointer`}
                onClick={() => toggleActiveLink("goals")}
              >
                <a href="/#goals">goals</a>
              </li>
              <li
                className={`cursor-pointer `}
                onClick={() => toggleActiveLink("team")}
              >
                <a href="/#teams">team</a>
              </li>
              <li
                className={`cursor-pointer`}
                onClick={() => toggleActiveLink("works")}
              >
                <a href="/#works">how it works</a>
              </li>
              <li
                className={`cursor-pointer`}
                onClick={() => toggleActiveLink("contact")}
              >
                <a href="/#contact">contact us</a>
              </li>
                </ul>
                <div className="flex flex-col gap-[1.6rem] font-medium text-[3rem]">
                  <div
                    className="flex justify-start"
                    onClick={() => {
                      setShowMenu(false);
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <a href="/#home">
              <img
                src="/assets/logo.svg"
                alt="HYDREATE"
                className="md:ml-6 lg:ml-0"
                onClick={() => toggleActiveLink("/")}
              />
            </a>
          </div>
          <div className="hidden lg:flex ">
            <ul className=" md:flex gap-[2rem] font-medium text-[1.6rem]">
              <li
                className={`cursor-pointer`}
                onClick={() => toggleActiveLink("about")}
              >
                <a href="/#about">about us</a>
              </li>
              <li
                className={`cursor-pointer`}
                onClick={() => toggleActiveLink("vision")}
              >
                <a href="/#vision">vision</a>
              </li>
              <li
                className={`cursor-pointer `}
                onClick={() => toggleActiveLink("mission")}
              >
                <a href="/#mission">mission</a>
              </li>
              <li
                className={`cursor-pointer`}
                onClick={() => toggleActiveLink("goals")}
              >
                <a href="/#goals">goals</a>
              </li>
              <li
                className={`cursor-pointer `}
                onClick={() => toggleActiveLink("team")}
              >
                <a href="/#teams">team</a>
              </li>
              <li
                className={`cursor-pointer`}
                onClick={() => toggleActiveLink("works")}
              >
                <a href="/#works">how it works</a>
              </li>
              <li
                className={`cursor-pointer`}
                onClick={() => toggleActiveLink("plans")}
              >
                <a href="/plans">plans</a>
              </li>
              <li
                className={`cursor-pointer`}
                onClick={() => toggleActiveLink("contact")}
              >
                <a href="/#contact">contact us</a>
              </li>
            </ul>
          </div>
          <div className="hidden md:flex items-center gap-[1.6rem] font-medium text-[1.6rem]">
            {userProfile?.token ?
              <div onClick={() => {
                window.localStorage.clear()
                removeUser()
              }}>
                <p className="cursor-pointer">log out</p>
              </div>
              :
              <Link to="/login">
                <p className="cursor-pointer">log in</p>
              </Link>
            }
            {userProfile?.token ?
              <Link to={userProfile?.user?.userRole === "LearningInstitution" ? "/li/dashboard" : userProfile?.user?.userRole === "Lecturer" ? "/lecturer/dashboard" : userProfile?.user?.userRole === "Student" ? "/student/dashboard" : "/dashboard"}>
                <Buttons variant="primary" text="DASHBOARD" />
              </Link>
              :
              <Link to="/get-started">
                <Buttons variant="primary" text="GET STARTED" />
              </Link>}
          </div>

          {/* Visible on small devices e.g mobile phones */}
          <div className="block md:hidden">
            {showMenu ? (
              <AiOutlineClose
                size={25}
                onClick={() => setShowMenu(false)}
                className="cursor-pointer"
              />
            ) : (
              <AiOutlineMenu
                size={25}
                onClick={() => setShowMenu(true)}
                className="cursor-pointer"
              />
            )}

            <div
              className={`absolute w-full bg-white z-50 ${showMenu ? "right-0" : "-right-full"
                } duration-300 min-h-screen text-right pr-[2.4rem] pt-[4rem] space-y-[3rem]`}
            >
              <ul className=" flex flex-col gap-[3rem] font-medium text-[3rem]">
                <li
                  className={`cursor-pointer `}
                  onClick={() => {
                    toggleActiveLink("about");
                    setShowMenu(false);
                  }}
                >
                  <a href="/#about">about us</a>
                </li>
                <li
                  className={`cursor-pointer `}
                  onClick={() => {
                    toggleActiveLink("vision");
                    setShowMenu(false);
                  }}
                >
                  <a href="/#vision">vision</a>
                </li>
                <li
                  className={`cursor-pointer `}
                  onClick={() => {
                    toggleActiveLink("mission");
                    setShowMenu(false);
                  }}
                >
                  <a href="/#mission">mission</a>
                </li>
                <li
                  className={`cursor-pointer `}
                  onClick={() => {
                    toggleActiveLink("goals");
                    setShowMenu(false);
                  }}
                >
                  <a href="/#goals">goals</a>
                </li>
                <li
                  className={`cursor-pointer `}
                  onClick={() => {
                    toggleActiveLink("team");
                    setShowMenu(false);
                  }}
                >
                  <a href="/#teams">team</a>
                </li>
                <li
                  className={`cursor-pointer `}
                  onClick={() => {
                    toggleActiveLink("works");
                    setShowMenu(false);
                  }}
                >
                  <a href="/#works">how it works</a>
                </li>
                <li
                  className={`cursor-pointer `}
                  onClick={() => {
                    toggleActiveLink("contact");
                    setShowMenu(false);
                  }}
                >
                  <a href="/#contact">contact us</a>
                </li>
              </ul>
              <div className="flex flex-col gap-[1.6rem] font-medium text-[3rem]">
                {/* <p
                  className="cursor-pointer"
                  onClick={() => {
                    toggleActiveLink("home");
                    setShowMenu(false);
                  }}
                >
                  log in
                </p> */}
                <div
                  className="flex justify-end"
                  onClick={() => {
                    toggleActiveLink("home");
                    setShowMenu(false);
                  }}
                >
                  <Link to="/get-started">
                    <Buttons variant="primary" text="GET STARTED" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default NavbarComponent;
