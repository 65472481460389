import React, { useState } from 'react'
import { IoIosArrowRoundForward } from 'react-icons/io'
import ModalContainer from '../CustomUIElements/Modal/ModalContainer'
import { LiaTimesSolid } from 'react-icons/lia'
import { useQuery } from 'react-query'
import { getMyCourses } from '../utils/students'
import useAuthStore from '../store/authStore'
import parse from "html-react-parser"
import Scale from '../CustomUIElements/Loaders/ScaleLoader'


function StudentCourses() {
    const [showDetails, setShowDetails] = useState(false)
    const [selectedCourse, setSelectedCourse] = useState(null)
    const { removeUser } = useAuthStore()
    const [courses, setCourses] = useState([])
    const { isLoading: loadingCourses } = useQuery("getMyCourses", getMyCourses, {
        enabled: true,
        refetchOnWindowFocus: true,
        retry: false,
        refetchInterval: false,
        onSuccess: ({ data }) => {
            console.log(data.data)
            setCourses(data?.data)
        },
        onError: ({ response }) => {
            if (response?.status === 401) {
                removeUser()
                window.location.href = "/login"
            }
        }
    })

    console.log(selectedCourse)

    return (
        <div className="relative top-[8%] px-16 py-8 bg-[#f1f1f1] min-h-[92vh]">
            <h1 className="text-[20px] font-semibold my-[3rem]">My Courses</h1>
            {loadingCourses ? <center><Scale /></center> :
                <div className="grid grid-cols-2 gap-[20px]">
                    {courses?.courses?.map((course) => (
                        <CourseCard key={course?.id} data={courses} courseDetails={course} setShowDetails={setShowDetails} setSelectedCourse={setSelectedCourse} />
                    ))}
                </div>
            }
            {showDetails && <ModalContainer width="600px" height="600px">
                <div className="p-8 border-t-[20px]" style={{borderColor: selectedCourse?.colour}}>
                    <div className="flex items-center justify-between text-[1.8rem] mb-4">
                        <h3 className="">More Information</h3>
                        <LiaTimesSolid
                            onClick={() => {
                                setShowDetails(false)
                                setSelectedCourse(null)
                            }
                            }
                            className="cursor-pointer"
                        />
                    </div>
                    <div>
                        <p className='text-[20px] font-semibold'>{selectedCourse?.name}</p>
                        <div className='mb-8 mt-1'>
                            <p className='text-[18px] font-medium'>Course Outline</p>
                            <div className='text-[15px] my-2'>
                                {parse(selectedCourse?.description)}
                            </div>
                        </div>
                        <div>
                            <p className='text-[18px] font-medium mb-4 border-t pt-2 border-[#5a5a5a]'>Group you belong to:</p>
                            <p className='font-semibold text-[15px] mb-1'>Group Name</p>
                            <p className='text-[14px] mb-4'>
                                {courses?.groups?.find((c, i) => c?.courseId === selectedCourse?.id)?.name}
                            </p>
                            <p className='font-semibold text-[15px] mb-1'>Group Description</p>
                            <p className='text-[14px]'>
                                {courses?.groups?.find((c, i) => c?.courseId === selectedCourse?.id)?.description}
                            </p>
                        </div>
                    </div>
                </div>
            </ModalContainer>}
        </div>
    )
}

export default StudentCourses


const CourseCard = ({ courseDetails, setShowDetails, setSelectedCourse, data }) => {
    return (
        <div className={`bg-white border rounded-xl pb-5 shadow-md  p-4 relative border-l-[20px]`} style={{borderColor: courseDetails?.colour}}>
            {/* <div style={{}}/> */}
            <p className='text-[20px] font-semibold'>{courseDetails?.name}</p>
            <p className='my-2 text-[16px] text-justify'>{parse(courseDetails?.description.substring(0, 340))}</p>
            <div className='text-[#008FD2] font-medium flex items-center gap-[5px] cursor-pointer mt-[15px]' onClick={() => { setShowDetails(true); setSelectedCourse(courseDetails) }}>
                <p className='text-[15px] '>Read More</p>
                <IoIosArrowRoundForward size={18} />
            </div>
        </div>
    )
}