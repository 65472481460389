import React, { useState } from "react";
import useIdeaStore from "../../../store/ideaStore";
import findCategory from "../../../utils/findCategory";
import moment from "moment";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { MdModeEditOutline } from "react-icons/md";
import { RiCheckboxBlankLine, RiCheckboxLine } from "react-icons/ri";
import { AiOutlineShareAlt } from "react-icons/ai";
import { HiOutlineTrash } from "react-icons/hi";
import { useMutation, useQuery } from "react-query";
import { deleteIdea } from "../../../utils/deleteIdea";
import { useNavigate, useParams } from "react-router-dom";
import CreateIdeaHeader from "../CreateIdea/CreateIdeaHeader";
import CreateIdeaContent from "../CreateIdea/CreateIdeaContent";
import BorderedInputs from "../../../CustomUIElements/Inputs/BorderInputs";
import { Buttons } from "../../../CustomUIElements/Buttons/Buttons";
import BorderedTextArea from "../../../CustomUIElements/Inputs/BorderTextArea";
import BorderedSelect from "../../../CustomUIElements/Inputs/BorderedSelect";
import { updateIdea } from "../../../utils/updateIdea";
import useAuthStore from "../../../store/authStore";
import { fetchSingleIdea } from "../../../utils/fetchSingleIdea";
import ConfirmationModal from "../../../CustomUIElements/Modal/ConfirmationModal";
import { makeAsVisible } from "../../../utils/makeAsVisible";
import { MdArrowBackIosNew } from "react-icons/md";

function Header({ categoryId, singleIdea, src }) {
  const { id } = useParams();
  const { categories, setSingleIdea } = useIdeaStore();
  const { userProfile, removeUser } = useAuthStore();
  const category = findCategory(categories, categoryId);
  const [showMenu, setShowMenu] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [wordCount, setWordCount] = useState(singleIdea?.description?.length);
  const [editData, setEditData] = useState({
    name: singleIdea?.name,
    description: singleIdea?.description,
    categoryId: category?.id,
    categoryName: category?.name,
  });
  const [editStep, setEditStep] = useState(1);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const navigate = useNavigate();
  const { isLoading: isDeleting, mutate: deleteAnIdea } = useMutation(
    () => deleteIdea(id),
    {
      onSuccess: ({ data }) => {
        console.log("data", data);
        navigate(-1);
      },
      onError: ({ response }) => {
        console.log("error", response);
      },
    }
  );

  const handleOptionCick = (name) => {
    switch (name) {
      case "Delete":
        setIsDeleteOpen(true);
        break;
      case "Edit":
        setOpenEditModal(true);
        break;
      default:
        break;
    }
  };

  const { refetch } = useQuery(
    "fetchSingleIdea",
    () => fetchSingleIdea({ id: parseInt(id) }),
    {
      onSuccess: ({ data }) => {
        setSingleIdea(data.data);
      },
      onError: ({ response }) => {
        if (response.data.status === 401) {
          removeUser();
        }
      },
      enabled: false,
    }
  );

  const { isLoading, mutate } = useMutation(updateIdea, {
    onSuccess: ({ data }) => {
      console.log("data", data);
      setEditStep(1);
      setOpenEditModal(false);
      refetch();
    },
  });

  const markIdeaHandler = async (data) => {
    await makeAsVisible(data).then((res) => {
      console.log(res)
      refetch()
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    mutate({
      id: id,
      name: editData?.name,
      description: editData?.description,
      categoryId: editData?.categoryId,
      creatorId: userProfile?.user?.id,
      groupId: 0,
      funnelId: 0,
    });
  };

  return (
    <div className="pb-[2.5rem] border-b border-[#E4E4E4]">
      <span className={`${src && "mt-[100px]"} block mb-[2rem] cursor-pointer`}>
        <MdArrowBackIosNew size={20} onClick={() => navigate(-1)} />
      </span>
      <div className="flex justify-between items-center">
        <div>
          <p
            style={{ background: `${category?.colour}` }}
            className="bg-[#008FD226] w-max py-[.6rem] px-[1.2rem] rounded-[1.5rem] text-[1.2rem] text-[#FFFFFF] font-medium mb-[1.2rem]"
          >
            {category?.name}
          </p>
          <h2 className="text-[4rem] text-[#103240] font-bold mb-[.8rem] leading-[4rem]">
            {singleIdea?.name}
          </h2>
          <p className="text-[1.2rem] text-[#10324080] mb-[1.6rem]">
            {moment(singleIdea?.dateAdded).format("D MMM [at] h:mma")}
          </p>
        </div>
        {src !== "lecturer" &&
        <div className="flex flex-col justify-between items-center gap-[2.4rem]">
          <span className="relative">
            <BiDotsVerticalRounded
              size={24}
              onClick={() => setShowMenu(!showMenu)}
              className="cursor-pointer"
            />
            {showMenu && (
              <div className="absolute w-[26.2rem] bg-white shadow-lg right-0 rounded-[1.2rem]">
                {PopupList.map(({ name, icon }) => {
                  return (
                    <div
                      className="p-4  flex justify-between items-center cursor-pointer hover:bg-[#F7F7F7]"
                      key={name}
                      onClick={() => handleOptionCick(name)}
                    >
                      <div className="flex gap-[1.2rem] items-center">
                        {icon}
                        <p className="text-[#103240] text-[1.6rem]">{name}</p>
                      </div>
                    </div>
                  );
                })}
                <div
                  className="p-4  flex justify-between items-center cursor-pointer hover:bg-[#F7F7F7]"
                  onClick={() => markIdeaHandler({
                    id: parseInt(id),
                    makeVisibleToInvestors: singleIdea?.makeVisibleToInvestors ? false : true,
                    textForInvestors: "Hi"
                  })}
                >
                  <div className="flex gap-[1.2rem] items-center">
                    {singleIdea?.makeVisibleToInvestors ? <RiCheckboxLine size={20} color="#103240" /> : <RiCheckboxBlankLine size={20} color="#103240" />}
                    <p className="text-[#103240] text-[1.6rem]">{`Make ${singleIdea?.makeVisibleToInvestors ? "invisible" : "visible"} to investors`}</p>
                  </div>
                </div>
              </div>
            )}
          </span>
          <div className="flex flex-col items-center text-[#59C159]">
            <h3 className="text-[3.2rem] font-semibold">
              {Math.round(singleIdea?.progressScore)}
              <sup>%</sup>
            </h3>
            <p className="text-[1.4rem] font-medium">Progress</p>
          </div>
        </div>
        }
      </div>
      <p className="text-[#103240E6] text-[1.6rem] font-medium">
        {singleIdea?.description}
      </p>
      {openEditModal && (
        <div className="fixed inset-0 z-50 h-screen bg-white">
          <CreateIdeaHeader setOpenCreateModal={setOpenEditModal} action="Edit an Idea" />
          <div className="w-[65rem] mx-auto mt-[4.8rem]">
            <h3 className=" text-[2.4rem] font-semibold">Edit an Idea</h3>
            <CreateIdeaContent>
              <div>
                {editStep === 1 && (
                  <div className="space-y-[2rem]">
                    <div>
                      <label className="text-[2.4rem] text-[#10324099]">
                        Name of Idea
                      </label>
                      <BorderedInputs
                        label="Name of Idea"
                        isRequired
                        value={editData?.name}
                        handleChange={(e) =>
                          setEditData({ ...editData, name: e.target.value })
                        }
                      />
                    </div>
                    <div>
                      <label className="text-[2.4rem] text-[#10324099]">
                        Write a short description
                      </label>
                      <BorderedTextArea
                        label="Write a short description"
                        value={editData?.description}
                        maxWordCount="150"
                        handleChange={(e) => {
                          setEditData({
                            ...editData,
                            description: e.target.value,
                          });
                          setWordCount(e.target.value.length);
                        }}
                      />
                      <p className="!ml-auto !mt-[-.2rem] text-right text-[#103240B2] font-medium">
                        {wordCount}/150
                      </p>
                    </div>
                    <div className="flex justify-end ml-auto mt-4 ">
                      <div className="flex gap-[1.6rem]">
                        <Buttons
                          text="Cancel"
                          variant="cancel"
                          handleClick={() => {
                            setEditData({
                              name: singleIdea?.name,
                              description: singleIdea?.description,
                            });
                            setWordCount(singleIdea?.description?.length);
                          }}
                        />
                        <Buttons
                          text="Next"
                          variant={
                            !editData?.name || !editData?.description
                              ? "disabled"
                              : "primary"
                          }
                          handleClick={() => setEditStep(2)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {editStep === 2 && (
                  <div className="space-y-[2rem]">
                    <div>
                      <BorderedSelect
                        label="Choose Category"
                        options={categories}
                        addIdea={editData}
                        setAddIdea={setEditData}
                      />
                    </div>
                    <div className="flex justify-end ml-auto mt-4 ">
                      <div className="flex gap-[1.6rem]">
                        <Buttons
                          text="Back"
                          variant="cancel"
                          handleClick={() => {
                            setEditStep(1);
                          }}
                        />
                        <Buttons
                          text="Save"
                          variant={
                            !editData?.name ||
                              !editData?.description ||
                              isLoading
                              ? "disabled"
                              : "primary"
                          }
                          handleClick={handleSubmit}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </CreateIdeaContent>
          </div>
        </div>
      )}
      <ConfirmationModal
        message="Are you sure you want to delete this idea?"
        showModal={isDeleteOpen}
        onConfirm={() => deleteAnIdea()}
        onCancel={() => setIsDeleteOpen(false)}
      />
    </div>
  );
}

export default Header;

export const PopupList = [
  // {
  //   name: "Share to",
  //   icon: <AiOutlineShareAlt size={20} color="#103240" />,
  // },
  {
    name: "Edit",
    icon: <MdModeEditOutline size={20} color="#103240" />,
  },
  {
    name: "Delete",
    icon: <HiOutlineTrash size={20} color="#103240" />,
  },
];
