import { create } from "zustand";
import { persist } from "zustand/middleware";
import { devtools } from "zustand/middleware";

const authStore = (set) => ({
  userProfile: null,
  saveUserProfile: (user) => set({ userProfile: user }),
  removeUser: () => {localStorage.clear();
    set({ userProfile: null })
  },
  userNotifications: [],
  saveUserNotifications: (userNotifications) => set({ userNotifications }),
  
  userNotificationsCount: [],
  saveUserNotificationsCount: (userNotificationsCount) => set({ userNotificationsCount }),
});

const useAuthStore = create(
  persist(devtools(authStore), {
    name: "userProfile",
  })
);

export default useAuthStore;
